<section
  class="ids-theme-varejo itau-cookie-consent-banner teste"
  [id]="idPrefix"
  *ngIf="showBanner">
  <div [class]="idPrefix + '__container'" *ngIf="!isMobile; else mobileScreen">
    <div [class]="idPrefix + '__close'">
      <button
        idsIconButton
        variant="neutral"
        title="fechar"
        attr.aria-label="{{ 'BUTTONS.CLOSE' | translate }}"
        (click)="onCloseBanner()">
        fechar
      </button>
    </div>
    <div [class]="idPrefix + '__content'">
      <div [class]="idPrefix + '__content--terms-policy'">
        <p class="ids-body-text" [id]="idPrefix + '-terms-policy'">
          {{ 'BANNER_TEXT' | translate }}
          <a
            class="ids-link"
            target="_blank"
            rel="noopener"
            attr.href="{{ 'POLICY_PRIVACY_LINK' | translate }}"
            (click)="sendAnalyticsEvent({
                eventName: 'click',
                detailText: 'btn:consentimento-cookies:banner:politica-privacidade-cookies',
            })"
            >{{ 'POLICY_PRIVACY_TEXT' | translate }}</a
          >.
        </p>
      </div>
      <div [class]="idPrefix + '__content--buttons'">
        <a
          tabindex="0"
          [id]="idPrefix + '-manage-cookies-link'"
          class="ids-link"
          role="link"
          (click)="openModal()"
          >{{ 'BUTTONS.MANAGE' | translate }}</a
        >
        <button
          idsMainButton
          variant="secondary"
          [id]="idPrefix + '-reject-cookies-btn'"
          (click)="rejectNonEssentialCookies()">
          {{ 'BUTTONS.REJECT' | translate }}
        </button>
        <button
          idsMainButton
          [id]="idPrefix + '-accept-cookies-btn'"
          (click)="acceptAllCookies()">
          {{ 'BUTTONS.ACCEPT_ALL' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-template #mobileScreen>
    <div [class]="idPrefix + '__container'">
      <div [class]="idPrefix + '__content'">
        <div [class]="idPrefix + '__close'">
          <button
            idsIconButton
            variant="neutral"
            title="fechar"
            attr.aria-label="{{ 'BUTTONS.CLOSE' | translate }}"
            (click)="onCloseBanner()">
            fechar
          </button>
        </div>
        <div [class]="idPrefix + '__content--terms-policy'">
          <p class="ids-body-text" [id]="idPrefix + '-terms-policy'">
            {{ 'BANNER_TEXT' | translate }}
            <a
              rel="noopener"
              class="ids-link"
              target="_blank"
              attr.href="{{ 'POLICY_PRIVACY_LINK' | translate }}"
              (click)="sendAnalyticsEvent({
                eventName: 'click',
                detailText: 'btn:consentimento-cookies:banner:politica-privacidade-cookies',
              })"
              >{{ 'POLICY_PRIVACY_TEXT' | translate }}</a
            >.
          </p>
        </div>
      </div>
      <hr class="ids-divider -soft" />
      <div [class]="idPrefix + '__content--buttons'">
        <a
          tabindex="0"
          [id]="idPrefix + '-manage-cookies-link'"
          class="ids-link"
          role="link"
          (click)="openModal()"
          >{{ 'BUTTONS.MANAGE' | translate }}</a
        >
        <button
          idsMainButton
          variant="secondary"
          [id]="idPrefix + '-reject-cookies-btn'"
          (click)="rejectNonEssentialCookies()">
          {{ 'BUTTONS.REJECT' | translate }}
        </button>
        <button
          idsMainButton
          [id]="idPrefix + '-accept-cookies-btn'"
          (click)="acceptAllCookies()">
          {{ 'BUTTONS.ACCEPT_ALL' | translate }}
        </button>
      </div>
    </div>
  </ng-template>
</section>
<itau-cookie-consent-modal
  [isOpen]="isOpenModal"
  [userHasAlreadyInteracted]="isShowPreferencesButton"
  (onChange)="onChange($event)"
  (userHasInteractedToModal)="
    userHasInteractedToModal = $event
  "></itau-cookie-consent-modal>
<button
  class="fab-button ids-theme-varejo"
  [ngClass]="isMobileIDS ? 'isMobile' : ''"
  attr.aria-label="{{ 'BUTTONS.MANAGE' | translate }}"
  role="button"
  (click)="showModal()"
  *ngIf="isShowPreferencesButton">
  <p>
    <span>{{ 'BUTTONS.MANAGE' | translate }}</span>
  </p>
  <ids-icon size="L">cookies_base</ids-icon>
</button>
