<ids-modal
  [(open)]="isOpen"
  (openChange)="openChange($event)"
  width="480px"
  class="cookie-consent-modal ids-theme-varejo">
  <ids-modal-header #header [noDivider]="true">
    <h2 idsModalTitle>{{ 'MODAL.TITLE' | translate }}</h2>
  </ids-modal-header>

  <form [formGroup]="form" class="ids-px-6">
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">
          {{ 'MODAL.ESSENTIAL_COOKIES.TITLE' | translate }}
        </h3>
        <p class="subtitle">
          {{ 'MODAL.ESSENTIAL_COOKIES.DESCRIPTION' | translate }}
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0001" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">
          {{ 'MODAL.FUNCTIONALITY_COOKIES.TITLE' | translate }}
        </h3>
        <p class="subtitle">
          {{ 'MODAL.FUNCTIONALITY_COOKIES.DESCRIPTION' | translate }}
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0003" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">
          {{ 'MODAL.ADVERTISING_COOKIES.TITLE' | translate }}
        </h3>
        <p class="subtitle">
          {{ 'MODAL.ADVERTISING_COOKIES.DESCRIPTION' | translate }}
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0004" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <h3 class="title ids-d-block ids-pb-1">
          {{ 'MODAL.ANALYTIC_COOKIES.TITLE' | translate }}
        </h3>
        <p class="subtitle">
          {{ 'MODAL.ANALYTIC_COOKIES.DESCRIPTION' | translate }}
        </p>
      </div>
      <ids-form-selection-group>
        <ids-form-selection>
          <input type="checkbox" formControlName="C0002" idsSwitch />
        </ids-form-selection>
      </ids-form-selection-group>
    </section>
    <section class="ids-d-flex ids-align-items-center">
      <div class="ids-py-4 ids-pr-2">
        <p class="subtitle">
          {{ 'MODAL.POLICY_PRIVACY_TEXT' | translate }}
          <a
            rel="noopener"
            class="ids-link"
            target="_blank"
            attr.href="{{ 'POLICY_PRIVACY_LINK' | translate }}"
            (click)="sendAnalyticsEvent()"
            >{{ 'MODAL.POLICY_PRIVACY_LINK' | translate }}</a
          >.
        </p>
      </div>
    </section>
  </form>

  <ids-modal-footer>
    <button
      idsMainButton
      variant="secondary"
      (click)="handleRejectNotEssentials()">
      {{ 'BUTTONS.REJECT' | translate }}
    </button>
    <button idsMainButton (click)="handleSave()">
      {{ 'BUTTONS.SAVE' | translate }}
    </button>
  </ids-modal-footer>
</ids-modal>
