import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable()
export class CdkCustomOverlayContainer extends OverlayContainer {
  /**
   * Attempts to find a shadow root to attach the overlay container.
   * Falls back to the original cdk-overlay-container if not found.
   */
  findMfeShadowRootForAppend(): ShadowRoot | Element {
    const primaryComponent = document.querySelector(
      'itau-cookie-consent-banner',
    );
    if (primaryComponent && primaryComponent.shadowRoot) {
      return primaryComponent.shadowRoot;
    }
    return document.querySelector('cdk-overlay-container') as
      | Element
      | ShadowRoot;
  }

  /**
   * Overrides the creation of the container element to use a custom method.
   */
  protected _createContainer(): void {
    const container = document.createElement('div');
    container.classList.add(
      'cdk-overlay-container-cookies',
      'ids-theme-varejo',
    );

    const mfeShadowRoot = this.findMfeShadowRootForAppend();
    if (mfeShadowRoot) {
      mfeShadowRoot.appendChild(container);
      this._containerElement = container;
    } else {
      throw new Error(
        'ShadowRoot or Element not found for appending the container.',
      );
    }
  }

  getContainerElement(): HTMLElement {
    if (!this._containerElement) {
      this._createContainer();
    }
    return this._containerElement;
  }
}
