import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  IdsFormSelectionComponent,
  IdsModalModule,
  IdsFormSelectionGroupComponent,
  IdsSwitchDirective,
  IdsMainButtonComponent,
} from '@ids/angular';
import { CookiesConsentService } from '../service/cookies-consent.service';
import { AnalyticsService } from '../service/analytics.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'itau-cookie-consent-modal',
  templateUrl: './cookie-consent-modal.component.html',
  styleUrls: ['./cookie-consent-modal.component.scss'],
  standalone: true,
  imports: [
    IdsModalModule,
    FormsModule,
    ReactiveFormsModule,
    IdsFormSelectionGroupComponent,
    IdsFormSelectionComponent,
    IdsSwitchDirective,
    IdsMainButtonComponent,
    TranslateModule,
  ],
})
export class CookieConsentModalComponent implements AfterViewInit {
  @Input() isOpen: boolean = false;
  @Input() userHasAlreadyInteracted: boolean = false;
  @Output() onChange = new EventEmitter<boolean>();
  @Output() userHasInteractedToModal = new EventEmitter<boolean>();

  @ViewChild('header', { read: ElementRef })
  idsHeader: ElementRef;

  @ViewChildren(IdsFormSelectionComponent, { read: ElementRef })
  idsFormSelection: QueryList<ElementRef>;

  form = new FormGroup({
    C0001: new FormControl({ value: true, disabled: true }),
    C0003: new FormControl(false),
    C0004: new FormControl(false),
    C0002: new FormControl(false),
  });
  activeCookies: any = [];
  currentLanguage: string;

  constructor(
    private cookiesConsentService: CookiesConsentService,
    private analyticsService: AnalyticsService,
  ) {
    this.updateCookies();
    this.currentLanguage = document.documentElement.lang.toLocaleLowerCase();
  }

  ngAfterViewInit(): void {
    const closeBtnIds = this.idsHeader.nativeElement.querySelector(
      'button[idsmodalclose]',
    );
    closeBtnIds.removeAttribute('title');
    closeBtnIds.removeAttribute('ng-reflect-title');

    if (this.currentLanguage === 'pt-br') {
      closeBtnIds.setAttribute('aria-label', 'Fechar');
    } else {
      closeBtnIds.setAttribute('aria-label', 'Close');
    }

    const detailTextAnalytics = this.userHasAlreadyInteracted
      ? 'regerenciamento'
      : 'gerenciamento';

    this.idsHeader.nativeElement
      .querySelector('button[idsmodalclose]')
      .addEventListener('click', () => {
        this.analyticsService.clickTrack({
          eventName: 'click',
          detailText: `btn:${detailTextAnalytics}-cookies:fechar`,
        });
      });

    this.idsHeader.nativeElement
      .querySelector('h2')
      .removeAttribute('tabindex');

    this.idsFormSelection.toArray().forEach((el, index) => {
      this.setAriaLabelOfSwitch(el, index);
    });

    this.form.valueChanges.subscribe(value => {
      this.activeCookies = Object.entries(value);
    });
  }

  ngOnChanges() {
    if (this.isOpen) {
      const detailTextVisualizationAnalytics = this.userHasAlreadyInteracted
        ? 'regerenciamento'
        : 'gerenciamento';
      this.updateCookies();
      this.analyticsService.screenviewTrack({
        eventName: 'visualization',
        detailText: `banner-${detailTextVisualizationAnalytics}-cookies`,
      });
    }
  }

  setAriaLabelOfSwitch(
    el: ElementRef<IdsFormSelectionComponent>,
    index: number,
  ) {
    const $el = el as unknown as ElementRef;
    $el.nativeElement.querySelector('label').style.display = 'none';

    const labels = {
      pt: [
        'Cookies Essenciais',
        'Cookies de Funcionalidade',
        'Cookies de Publicidade',
        'Cookies Analíticos',
      ],
      en: [
        'Essential Cookies',
        'Functionality Cookies',
        'Advertising Cookies',
        'Analytical Cookies',
      ],
    };

    const language = this.currentLanguage.startsWith('pt') ? 'pt' : 'en';
    const label = labels[language][index] || '';

    $el.nativeElement.querySelector('label').innerText = label;
  }

  handleSave() {
    let activeCookies = '';
    Object.entries(this.form.controls).forEach(([cookieKey, cookieValue]) => {
      if (cookieKey !== 'C0001') {
        const action = cookieValue.value
          ? this.activateCookie
          : this.deactivateCookie;
        action.call(this, cookieKey);
        if (cookieValue.value) {
          activeCookies += activeCookies
            ? `|${this.mappingCookies(cookieKey)}`
            : this.mappingCookies(cookieKey);
        }
      }
    });

    setTimeout(() => {
      this.updateCookies();
      this.userHasInteractedToModal.emit(true);
      this.openChange(false);
      this.sendAnalytics(activeCookies);
    }, 400);
  }

  getCookieStatus() {
    let cookies = window.document.cookie
      .split('&')
      .filter(item => !item.indexOf('groups'))[0];
    cookies = cookies?.split('=')[1];
    cookies = decodeURIComponent(cookies || '');
    return cookies.split(',').map(item => item.split(':'));
  }

  updateCookies() {
    let cookies = this.getCookieStatus();
    this.cookiesConsentService.$cookies.subscribe((cookies: string[]) => {
      cookies.forEach(cookie => {
        this.form.patchValue({
          [cookie]: true,
        });
      });
    });

    cookies.forEach(([cookieKey, cookieValue]) => {
      this.form.patchValue({
        [cookieKey]: parseInt(cookieValue) === 1,
      });

      if (cookieKey !== 'C0001' && this.userHasAlreadyInteracted) {
        this.userHasInteractedToModal.emit(true);
      }
    });
  }

  activateCookie(cookieType: string) {
    window?.OneTrust.UpdateConsent('Category', `${cookieType}:1`);
  }

  deactivateCookie(cookieType: string) {
    window?.OneTrust.UpdateConsent('Category', `${cookieType}:0`);
  }

  mappingCookies(cookieType: string) {
    const cookies: { [key: string]: string } = {
      C0002: 'analiticos',
      C0003: 'funcionalidade',
      C0004: 'publicidade',
    };
    return cookies[cookieType];
  }

  sendAnalytics(activeCookies: string) {
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText:
        activeCookies.length > 0
          ? `btn:${
              this.userHasAlreadyInteracted
                ? 'regerenciamento'
                : 'gerenciamento'
            }-cookies:salvar:${activeCookies}`
          : `btn:${
              this.userHasAlreadyInteracted
                ? 'regerenciamento'
                : 'gerenciamento'
            }-cookies:rejeitar-nao-essenciais`,
    });
  }

  handleRejectNotEssentials() {
    window?.OneTrust.RejectAll();
    setTimeout(() => {
      this.userHasInteractedToModal.emit(true);
      this.openChange(false);
      this.updateCookies();
      this.analyticsService.clickTrack({
        eventName: 'click',
        detailText: `btn:${
          this.userHasAlreadyInteracted ? 'regerenciamento' : 'gerenciamento'
        }-cookies:rejeitar-nao-essenciais`,
      });
    }, 300);
  }

  openChange(event: boolean) {
    this.onChange.emit(event);
  }

  sendAnalyticsEvent() {
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: `btn:${
        this.userHasAlreadyInteracted ? 'regerenciamento' : 'gerenciamento'
      }:politica-privacidade-cookies`,
    });
  }
}
