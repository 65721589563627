import {
  Component,
  HostListener,
  OnInit,
  Input,
  AfterContentInit,
  ViewEncapsulation,
} from '@angular/core';
import { MfeStyleManagerService } from '../core/services/mfe-style-manager/mfe-style-manager.service';
import { CookiesConsentService } from '../service/cookies-consent.service';
import { AnalyticsService, EventGA } from '../service/analytics.service';
import { IdsBannerSegmentService } from '../core/services/ids-banner-segment/ids-banner-segment.service';
import { NgIf, NgClass } from '@angular/common';
import { SegmentValueTypes } from '@ids/tools';
import { platform } from '@ids/tools';
import { TranslateModule } from '@ngx-translate/core';
import { CookieConsentModalComponent } from '../cookie-consent-modal/cookie-consent-modal.component';
import {
  IdsIconButtonComponent,
  IdsMainButtonComponent,
  IdsIconModule,
} from '@ids/angular';

@Component({
  selector: 'itau-cookie-consent-banner',
  styleUrls: ['./cookie-consent-banner.component.scss'],
  templateUrl: './cookie-consent-banner.component.html',
  standalone: true,
  imports: [
    NgIf,
    IdsIconButtonComponent,
    IdsMainButtonComponent,
    CookieConsentModalComponent,
    NgClass,
    IdsIconModule,
    TranslateModule,
  ],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CookieConsentBannerComponent implements OnInit, AfterContentInit {
  @Input() customPathGa4?: string;
  idPrefix = 'itau-cookie-consent-banner';
  private _showBanner = true;
  isShowPreferencesButton = false;
  userHasInteractedToModal = false;
  isOpenModal = false;
  activeCookies: string[] = [];
  segment: SegmentValueTypes = 'varejo';
  isMobile: boolean = window.innerWidth < 768;
  isMobileIDS: boolean = platform.isMobile;

  constructor(
    private mfeStyle: MfeStyleManagerService,
    private cookiesConsentService: CookiesConsentService,
    private analyticsService: AnalyticsService,
    private idsBannerSegmentService: IdsBannerSegmentService,
  ) {}

  get showBanner() {
    return !this.getOptanonConsentCookie() && this._showBanner;
  }

  set showBanner(value) {
    this._showBanner = value;
  }

  ngOnInit() {
    this.idsBannerSegmentService.createSegmentAliasForBanner();
    this.mfeStyle.mountStyles();
    this.analyticsService.setCustomPathGA4(this.customPathGa4);
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 768;
    this.isMobileIDS = platform.isMobile;
  }

  ngAfterContentInit(): void {
    this.showPreferencesButton();
    this.analyticsService.screenviewTrack({
      eventName: 'visualization',
      detailText: 'banner-consentimento-cookies:banner',
    });
  }

  ngOnDestroy() {
    this.mfeStyle.unmountStyles();
  }

  onCloseBanner() {
    window.OneTrust.SetAlertBoxClosed();
    this.showBanner = false;
    this.showPreferencesButton();
    this.sendAnalyticsEvent({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:banner:fechar',
    });
  }

  acceptAllCookies() {
    // Aqui você pode permitir todos os cookies
    window.OneTrust.AllowAll();
    this.showBanner = false;
    this.getActiveCookies();
    this.showPreferencesButton();
    this.sendAnalyticsEvent({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:banner:aceitar-todos',
    });
  }

  rejectNonEssentialCookies() {
    // Aqui você pode definir apenas os cookies essenciais
    window.OneTrust.RejectAll();
    this.showBanner = false;
    this.showPreferencesButton();
    this.sendAnalyticsEvent({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:banner:rejeitar-nao-essenciais',
    });
  }

  openModal() {
    this.isOpenModal = true;
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:banner:gerenciar-cookies',
    });
  }

  onChange(isOpen: boolean) {
    this.showBanner = !isOpen && !this.userHasInteractedToModal;
    this.isOpenModal = isOpen;
    this.showPreferencesButton();
  }

  showModal() {
    this.isOpenModal = true;
    this.sendAnalyticsEvent({
      eventName: 'click',
      detailText: 'btn:regerenciamento-cookies',
    });
  }

  showPreferencesButton() {
    this.isShowPreferencesButton = this.showBanner ? false : true;
  }

  getActiveCookies() {
    let activeCookies = window.OnetrustActiveGroups.split(',').filter(
      (item: string) => item !== '',
    );
    this.cookiesConsentService.$cookies.next(activeCookies);
  }

  sendAnalyticsEvent(event: EventGA) {
    this.analyticsService.clickTrack({
      eventName: event.eventName,
      detailText: event.detailText,
    });
  }

  getOptanonConsentCookie() {
    let cookies = this.getCookies();
    return cookies.includes('OptanonAlertBoxClosed');
  }

  private getCookies() {
    return document.cookie.split(';').map(cookie => {
      const [key] = cookie.split('=');

      return key.trim();
    });
  }
}
