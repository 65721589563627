import { DOCUMENT, NgIf } from '@angular/common';
import {
  AfterContentInit,
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import { IdsBannerSegmentService } from '../core/services/ids-banner-segment/ids-banner-segment.service';
import { MfeStyleManagerService } from '../core/services/mfe-style-manager/mfe-style-manager.service';
import { AnalyticsService } from '../service/analytics.service';
import { CookiesConsentService } from '../service/cookies-consent.service';

import { SegmentValueTypes } from '@ids/tools';
import { CookieConsentModalComponent } from '../cookie-consent-modal/cookie-consent-modal.component';
import {
  IdsModalModule,
  IdsIconButtonComponent,
  IdsMainButtonComponent,
  IdsIconComponent,
} from '@ids/angular';

@Component({
  selector: 'itau-cookie-consent-banner-v2',
  templateUrl: './cookie-consent-banner-v2.component.html',
  styleUrls: ['./cookie-consent-banner-v2.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    IdsModalModule,
    IdsIconButtonComponent,
    IdsMainButtonComponent,
    CookieConsentModalComponent,
    IdsIconComponent,
  ],
})
export class CookieConsentBannerV2Component
  implements OnInit, AfterContentInit
{
  idPrefix = 'itau-cookie-consent-banner-v2';
  private _showBanner = true;
  isShowPreferencesButton = false;
  userHasInteractedToModal = false;
  isOpenModal = false;
  activeCookies: string[] = [];
  @Input() segment: SegmentValueTypes = 'uniclass';
  @Input() customPathGa4?: string;
  isMobile: boolean = window.innerWidth < 768;

  isOpenNoFooter = true;

  constructor(
    private mfeStyle: MfeStyleManagerService,
    private cookiesConsentService: CookiesConsentService,
    private analyticsService: AnalyticsService,
    private idsBannerSegmentService: IdsBannerSegmentService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get showBanner() {
    return !this.getOptanonConsentCookie() && this._showBanner;
  }

  set showBanner(value) {
    this._showBanner = value;
  }

  ngOnInit() {
    this.idsBannerSegmentService.createSegmentAliasForBanner();
    this.mfeStyle.mountStyles();
    this.setIdInStyleTag(this.idPrefix);
    this.analyticsService.setCustomPathGA4(this.customPathGa4);
  }

  setIdInStyleTag(idPrefix: string) {
    const styles$ = this.document.querySelectorAll('style');
    styles$.forEach(style => {
      if (style.innerHTML.includes(idPrefix)) {
        style.id = 'banner-cookies';
      }
    });
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth <= 767;
  }

  async ngAfterContentInit(): Promise<void> {
    this.showPreferencesButton();
    this.analyticsService.screenviewTrack({
      eventName: 'visualization',
      detailText: 'banner-consentimento-cookies:modal',
    });
  }

  ngOnDestroy() {
    this.mfeStyle.unmountStyles();
  }

  onCloseBanner() {
    window.OneTrust.SetAlertBoxClosed();
    this.showBanner = false;
    this.showPreferencesButton();
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:modal:fechar',
    });
  }

  acceptAllCookies() {
    // Aqui você pode permitir todos os cookies
    window.OneTrust.AllowAll();
    this.showBanner = false;
    this.getActiveCookies();
    this.showPreferencesButton();
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:modal:aceitar-todos',
    });
  }

  rejectNonEssentialCookies() {
    // Aqui você pode definir apenas os cookies essenciais
    window.OneTrust.RejectAll();
    this.showBanner = false;
    this.showPreferencesButton();
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:modal:rejeitar-nao-essenciais',
    });
  }

  openModal() {
    this.isOpenModal = true;
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:consentimento-cookies:modal:gerenciar-cookies',
    });
  }

  clickPoliticaPrivacidade() {
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText:
        'btn:consentimento-cookies:modal:politica-privacidade-cookies',
    });
  }

  onChange(isOpen: boolean) {
    this.showBanner = !isOpen && !this.userHasInteractedToModal;
    this.isOpenModal = isOpen;
    this.showPreferencesButton();
  }

  showModal() {
    this.isOpenModal = true;
    this.analyticsService.clickTrack({
      eventName: 'click',
      detailText: 'btn:regerenciamento-cookies',
    });
  }

  showPreferencesButton() {
    this.isShowPreferencesButton = this.showBanner ? false : true;
  }

  getActiveCookies() {
    let activeCookies = window.OnetrustActiveGroups.split(',').filter(
      (item: string) => item !== '',
    );
    this.cookiesConsentService.$cookies.next(activeCookies);
  }

  getOptanonConsentCookie() {
    let cookies = this.getCookies();
    return cookies.includes('OptanonAlertBoxClosed');
  }

  private getCookies() {
    return document.cookie.split(';').map(cookie => {
      const [key] = cookie.split('=');

      return key.trim();
    });
  }
}
