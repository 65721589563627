import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from './../../../environments/environment';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    `${environment.cloudfrontURL}/assets/i18n/`,
    '.json',
  );
}

let pageLanguage = document.documentElement.lang.toLocaleLowerCase();
let pathName = window.location.pathname;
let containsPath =
  pathName === '/terms-and-conditions' ||
  pathName === '/privacy' ||
  pathName === '/privacy/privacy-and-cookie-policy'; // do not include new paths here!

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: pageLanguage,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class I18nModule {
  constructor(private readonly translate: TranslateService) {
    if (pageLanguage.startsWith('en')) {
      pageLanguage = 'en';
    } else if (containsPath) {
      pageLanguage = 'en';
    } else {
      pageLanguage = 'pt-br';
    }
    this.translate.use(pageLanguage);
  }
}
